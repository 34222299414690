<template>
    <img
        class="rounded-full bg-gray-600"
        :src="src"
        :data-initials="initials"
        :alt="alt"
    />
</template>

<script>
import { gravatarProfiles } from '@/utils';

export default {
    name: 'ProifleImage',

    props: {
        email: {
            type: String,
            required: true,
        },

        alt: {
            type: String,
            required: true,
        },

        initials: {
            type: String,
            required: true,
        },

        dimensions: {
            type: Number,
            required: true,
        },
    },

    computed: {
        src() {
            return gravatarProfiles.make({
                email: this.email,
                size: this.dimensions,
            });
        },
    },
};
</script>
